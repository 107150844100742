import axios from 'axios';

const state = {
    locations: {},
    uploadProgress: null,
    dashbardUserInfo : {},
    worldSchools: {}
};

const getters = {
    locations: state => state.locations,
    dashbardUserInfo: state => state.dashbardUserInfo,
    worldSchools: state => state.worldSchools,
};

const actions = {
    async getApplicationType(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.applicationTypeUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getExactApplications(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getExactApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getPaginatedExactApplications(state, url) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        // const url = state.rootState.url.getExactApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{},{ headers: header });// make api call
        return response.data;
    },
    async getApplication(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.applicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getLocations(state) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.locationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{}, { headers: header });// make api call
        state.commit('setLocations', response.data);
        return response.data;
    },
    async updateUserCpdPoint(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateUserCpdPointUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async updateUserData(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateUserDataUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async approveApplication(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.approveApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async rejectApplication(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.rejectApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async searchExactApplications(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.searchExactApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{data},{ headers: header });// make api call
        return response.data;
    },
    async getInternshipRecords(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getInternshipRecordsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async updateUserLicenseYear(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateUserLicenseYearUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getOldAQApplication(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getOldAQApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async approveOldAQApplication(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.approveOldAQApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async uploadEcisFile(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadEcisFileUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.state.uploadProgress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    async sendUserEcisMail(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.sendUserEcisMailUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {data},{ headers: header });// make api call
        return response.data;
    },
    async getDashboardInfo(state, id) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getDashboardInfoUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {'id' : id},
                        { headers: header });// make api call
        state.commit('setDashboardUserInfo', response.data);
    },
    async getWorldSchools(state) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getWorldSchoolsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{}, { headers: header });// make api call
        state.commit('setWorldSchools', response.data);
        return response.data;
    },
    async approveDiasporaLicenseApplication(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.approveDiasporaLicenseApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    },
    async getDiasporaExactApplications(state, data) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getDiasporaExactApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data,{ headers: header });// make api call
        return response.data;
    }
};

const mutations = {
    setLocations: (state, data) => (state.locations = data),// set locations (states, lgas, and countries)
    setDashboardUserInfo: (state, data) => (state.dashbardUserInfo = data),// set user dashboard
    setWorldSchools: (state, data) => (state.worldSchools = data),// set World Schools
};

export default {
    state,
    getters,
    actions,
    mutations
}